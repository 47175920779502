(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("moment"), require("antd"), require("ReactRouterDOM"));
	else if(typeof define === 'function' && define.amd)
		define("placeFile", ["React", "moment", "antd", "ReactRouterDOM"], factory);
	else if(typeof exports === 'object')
		exports["placeFile"] = factory(require("React"), require("moment"), require("antd"), require("ReactRouterDOM"));
	else
		root["placeFile"] = factory(root["React"], root["moment"], root["antd"], root["ReactRouterDOM"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__3__) {
return 