import React from "react";
import { withRouter } from "react-router-dom";
import PlaceInfo from "./components/PlaceDetail/PlaceInfo";
import VisitorsFlowrate from "./components/PlaceDetail/VisitorsFlowrate";
import PlaceLabel from "./components/PlaceDetail/PlaceLabel";
import PlaceDevice from "./components/PlaceDetail/PlaceDevice";
import TravelRule from "./components/PlaceDetail/TravelRule";
import PersonnelComposition from "./components/PlaceDetail/PersonnelComposition";
import "./index.less";
import { message } from "antd";
const SearchInput = Loader.loadBaseComponent("SearchInput");
const PlaceTableTree = Loader.loadBaseComponent("PlaceTableTree");

const Spin = Loader.Loading;

@withRouter
@Decorator.withEntryLog()
@Decorator.businessProvider("place", "user")
class placeFile extends React.Component {
  state = {
    placeInfo: {},
    resources: {},
    vehiclesResources: {},
    deviceList: [],
    deviceNum: 0,
    placeTypeNum: {},
    countPersonFrequencyType: 1,
    countPersonNumType: 2,
    placeNum: 0,
    tags: {
      placeTags: [],
      featureTags: []
    },
    initTags: {
      placeTags: [],
      featureTags: []
    },
    frequentPersonnelNum: 0,
    temporaryPersonnelNum: 0,
    unFrequentPersonnelNum: 0,
    countPersonNumData: {},
    countPersonFrequencyData: {},
    placeTreeData: [],
    placeArray: [],
    loading: false,
    placeTotal: 0,
    expandedRowKeys: [],
    keyword: null,
    placeData: {},
    labelVisible: false, // 标签弹窗
    tagsPlaces: []
  };

  componentWillMount() {
    let { place, user, history } = this.props;
    //获取用户能看到的场所id列表
    Service.organization
      .getPlaceIdsByUserId({
        organizationId: user.userInfo.organizationId
      })
      .then(res => {
        let placeArray = place.getPlaceInfoByIds(res.data.placeIds);
        let placeTreeData = Utils.computPlaceTree(placeArray);
        let placeTotal = 0;
        placeTreeData.forEach(v => {
          placeTotal += v.childrenPlaceCount;
        });
        let ids = Utils.queryFormat(history.location.search).id;
        window.LM_DB.get("parameter", ids || "placeFile").then(data => {
          let placeData = {};
          if (data) {
            placeData = placeArray.find(v => v.placeId === data.placeId);
          } else {
            placeData = placeTreeData[0] || {};
          }
          if (!placeData) {
            placeData = placeTreeData[0] || {};
          }
          this.setState(
            {
              placeTreeData,
              placeArray,
              placeTotal,
              placeData
            },
            () => {
              this.inputHandleChange();
              this.initData();
            }
          );
        });
      });
  }
  initData = () => {
    let {
      countPersonFrequencyType,
      countPersonNumType,
      placeData
    } = this.state;
    const { bigDatePlaceType } = Dict.map;
    if (!placeData.placeId) {
      return;
    }
    window.LM_DB.add("parameter", {
      id: "placeFile",
      placeId: placeData.placeId
    }).then(() => {
      this.setState({
        loading: true
      });
      Service.place
        .queryPlaceInfo(placeData.placeInfoUrl, {
          id: placeData.placeId
        })
        .then(res => {
          let data = res.data;
          this.setState({
            placeInfo: data,
            tags: {
              placeTags: data.placeTags || [],
              featureTags: data.featureTags || []
            },
            initTags: {
              placeTags: data.placeTags || [],
              featureTags: data.featureTags || []
            }
          });
          const cameraTypes = [];
          Dict.map.deviceType
            .filter(v => v.value !== "-1" && v.value !== "100605")
            .map(v => {
              let item = v.value.split(",");
              if (item.length > 1) {
                cameraTypes.push(item[0]);
                cameraTypes.push(item[1]);
              } else {
                cameraTypes.push(v.value);
              }
              return cameraTypes;
            });
          Promise.all([
            Utils.catchPromise(
              Service.device.queryDevices({
                placeIds: [placeData.placeId],
                queryType: 2,
                deviceTypes: cameraTypes,
                ignoreUserRestrictions: true
              })
            ),
            Utils.catchPromise(
              Service.place.countMinPlaces(data.countMinPlacesUrl, {
                placeId: placeData.placeId
              })
            ),
            Utils.catchPromise(
              Service.place.countResources(data.countResourcesUrl, {
                placeIds: [placeData.placeId]
              })
            ),
            Utils.catchPromise(
              Service.place.countVehiclesResources(
                data.countVehiclesResources,
                {}
              )
            )
          ])
            .then(resultArr => {
              this.setState({
                deviceList: resultArr[0] && resultArr[0].data.list,
                deviceNum: resultArr[0] && resultArr[0].data.total,
                placeNum: resultArr[1] && resultArr[1].data,
                resources: resultArr[2] && resultArr[2].data,
                vehiclesResources: resultArr[3] && resultArr[3].data,
                loading: false
              });
            })
            .catch(() => {
              this.setState({
                loading: false
              });
            });

          Promise.all([
            Utils.catchPromise(
              Service.place.countMinPlaces(data.countMinPlacesUrl, {
                placeId: placeData.placeId,
                tags: bigDatePlaceType.map(v => v.value)
              })
            ),
            Utils.catchPromise(
              Service.place.queryMinPlaces({
                placeId: placeData.placeId,
                tags: bigDatePlaceType.map(v => v.value)
              })
            )
          ]).then(resultArr => {
            this.setState({
              placeTypeNum: resultArr[0] && resultArr[0].data,
              tagsPlaces: resultArr[1] && resultArr[1].data,
            });
          });
          Utils.catchPromise(
            Service.place.countPersonFrequency(data.countPersonFrequency, {
              placeIds: [placeData.placeId],
              timeType: countPersonFrequencyType
            })
          ).then(res => {
            this.setState({
              countPersonFrequencyData: res && res.data
            });
          });
          Utils.catchPromise(
            Service.place.countPersonNum(data.countPersonNum, {
              placeIds: [placeData.placeId],
              timeType: countPersonNumType
            })
          ).then(res => {
            this.setState({
              countPersonNumData: (res && res.data) || []
            });
          });
          Promise.all([
            Utils.catchPromise(
              Service.place.countTypeByPid(data.countTypeByPidUrl, {
                inAndOutType: 1
              })
            ),
            Utils.catchPromise(
              Service.place.countTypeByPid(data.countTypeByPidUrl, {
                inAndOutType: 2
              })
            ),
            Utils.catchPromise(
              Service.place.countTypeByPid(data.countTypeByPidUrl, {
                inAndOutType: 3
              })
            ),
            Utils.catchPromise(
              Service.place.countTypeByPid(data.countTypeByPidUrl, {
                inAndOutType: 4
              })
            )
          ]).then(resultArr => {
            this.setState({
              frequentPersonnelNum: resultArr[0] && resultArr[0].data,
              temporaryPersonnelNum: resultArr[1] && resultArr[1].data,
              unFrequentPersonnelNum: resultArr[2] && resultArr[2].data,
              totalPersonnelNum: resultArr[3] && resultArr[3].data
            });
          });
        });
    });
  };

  //获取场所信息
  queryPlaceInfo = () => {
    let { placeData } = this.state;
    Service.place
      .queryPlaceInfo(placeData.placeInfoUrl, {
        id: placeData.placeId
      })
      .then(res => {
        let data = res.data;
        this.setState({
          placeInfo: data,
          tags: {
            placeTags: data.placeTags || [],
            featureTags: data.featureTags || []
          },
          initTags: {
            placeTags: data.placeTags || [],
            featureTags: data.featureTags || []
          }
        });
      });
  };

  //出行规律
  countPersonFrequency = (placeIds, timeType) => {
    let { placeInfo } = this.state;
    Service.place
      .countPersonFrequency(placeInfo.countPersonFrequency, {
        placeIds,
        timeType
      })
      .then(res => {
        this.setState({
          countPersonFrequencyData: res.data
        });
      });
  };
  countPersonFrequencyTypeChange = v => {
    let { placeData } = this.state;
    this.setState({ countPersonFrequencyType: v });
    this.countPersonFrequency([placeData.placeId], v);
  };
  //人流量分布规律
  countPersonNum = (placeIds, timeType) => {
    let { placeInfo } = this.state;
    Service.place
      .countPersonNum(placeInfo.countPersonNum, {
        placeIds,
        timeType
      })
      .then(res => {
        this.setState({
          countPersonNumData: res.data
        });
      });
  };
  countPersonNumTypeChange = v => {
    let { placeData } = this.state;
    this.setState({ countPersonNumType: v });
    this.countPersonNum([placeData.placeId], v);
  };
  //选择场所
  onSelectPlace = data => {
    let { placeData } = this.state;
    if (placeData.placeId === data.placeId) {
      return;
    }
    this.setState({ placeId: data.placeId, placeData: data }, () => {
      this.initData();
    });
  };
  //展开
  onExpand = (expanded, record) => {
    let { expandedRowKeys } = this.state;
    this.setState({
      expandedRowKeys: expanded
        ? expandedRowKeys.concat(record.placeId)
        : expandedRowKeys.filter(v => {
            return v !== record.placeId;
          })
    });
  };
  //搜索
  inputHandleChange = value => {
    const { place } = this.props;
    const { placeArray, placeData } = this.state;
    let placeId = placeData.placeId;
    let expandedRowKeys = [];
    if (!value) {
      if (placeId) {
        const id = placeArray.find(v => v.placeId === placeId).id;
        let placeIds = place.getParentPlaceListById(id).map(v => {
          if (v.placeId !== placeId) {
            return v.placeId;
          }
        });
        expandedRowKeys = placeIds;
      }
    } else {
      const orgs = placeArray.filter(v => v.name.indexOf(value) > -1);
      let placeIds = place
        .getParentPlaceListByIds(orgs.map(v => v.id))
        .map(v => v.placeId);
      expandedRowKeys = placeIds;
    }
    this.setState({
      expandedRowKeys,
      keyword: value
    });
  };
  //选标签
  itemClick = (value, type, arr) => {
    let { tags } = this.state;
    if (tags[type].indexOf(value) >= 0) {
      tags[type] = tags[type].filter(v => {
        return v !== value;
      });
    } else {
      if (arr.length) {
        tags[type] = tags[type].filter(v => {
          return arr.indexOf(v) < 0;
        });
      }
      tags[type].push(value);
    }
    this.setState({
      tags
    });
  };
  changeLabelModal = () => {
    this.setState({
      labelVisible: true
    });
  };
  handleLabelCancel = () => {
    this.setState({
      labelVisible: false,
      tags: this.state.initTags
    });
    this.queryPlaceInfo();
  };
  handleLabelOK = () => {
    let { placeData, tags } = this.state;
    let { placeTypeWithIcon, placeFeature } = Dict.map;
    if (tags.placeTags.length < 1) {
      message.warn("请为场所选择至少一种类型!!");
      return;
    }
    let placeTagsName = [];
    tags.placeTags &&
      !!tags.placeTags.length &&
      tags.placeTags.forEach(v => {
        placeTagsName.push(placeTypeWithIcon.find(x => x.value === v).label);
      });
    tags.featureTags &&
      !!tags.featureTags.length &&
      tags.featureTags.forEach(v => {
        placeTagsName.push(placeFeature.find(x => x.value === v).label);
      });
    Service.place
      .playPlaceTagsById(
        {
          placeId: placeData.placeId,
          placeTags: tags.placeTags,
          featureTags: tags.featureTags
        },
        {
          name: placeData.name,
          placeTagsName: placeTagsName
        }
      )
      .then(v => {
        message.success("设置标签成功");
        this.setState({
          labelVisible: false
        });
        this.queryPlaceInfo();
      });
  };

  render() {
    let {
      loading,
      placeData,
      placeInfo,
      resources,
      vehiclesResources,
      deviceList,
      deviceNum,
      placeNum,
      frequentPersonnelNum,
      temporaryPersonnelNum,
      unFrequentPersonnelNum,
      totalPersonnelNum,
      countPersonFrequencyData,
      countPersonFrequencyType,
      placeTypeNum,
      placeTreeData,
      countPersonNumType,
      countPersonNumData,
      placeTotal,
      expandedRowKeys,
      keyword,
      tags,
      labelVisible,
      tagsPlaces
    } = this.state;
    let personnelNum = {
      frequentPersonnelNum,
      temporaryPersonnelNum,
      unFrequentPersonnelNum,
      totalPersonnelNum
    };
    let placeInfoData = Object.assign(
      {},
      placeInfo,
      resources,
      vehiclesResources,
      {
        deviceNum
      },
      {
        placeNum
      }
    );
    let address = "";
    if (this.props.user.userInfo.addressCode) {
      let addressArr = this.props.user.userInfo.addressCode.split(",");
      address =
        addressArr &&
        Dict.map.location.find(
          v => v.code === addressArr[addressArr.length - 1]
        ).name;
    }

    return (
      <div className="place-file-view">
        <div className="place-file-nav">
          <div className="place-file-title">
            <div className="panel-name">场所档案&nbsp;&nbsp;{address}</div>
            <div className="place-num-box">
              <div className="place-num-title">场所数：</div>
              <div className="place-num  primary-color">{placeTotal}</div>
            </div>
          </div>
          <div className="place-file-header" />
        </div>
        <div className="place-file-content">
          <div className="place-list-view">
            <div className="list-search">
              <SearchInput
                size={"lg"}
                style={{ color: "rgba(0,0,0,.25)" }}
                placeholder="请输入场所名称进行查找"
                onChange={this.inputHandleChange}
              />
            </div>
            <PlaceTableTree
              data={placeTreeData}
              placeId={placeData.placeId}
              onSelect={this.onSelectPlace}
              expandedRowKeys={expandedRowKeys}
              onExpand={this.onExpand}
              keyword={keyword}
            />
          </div>
          <Spin spinning={loading} wrapperClassName="place-detail-view">
            <div className="place-content-view">
              <PlaceInfo placeInfoData={placeInfoData} />
              {placeInfo.level >= 4 && (
                <PlaceLabel
                  tags={tags}
                  itemClick={this.itemClick}
                  handleLabelCancel={this.handleLabelCancel}
                  handleLabelOK={this.handleLabelOK}
                  changeLabelModal={this.changeLabelModal}
                  labelVisible={labelVisible}
                />
              )}
              <PlaceDevice
                deviceList={deviceList}
                placeTypeNum={placeTypeNum}
                placeInfo={placeInfo}
                tagsPlaces={tagsPlaces}
              />
              <PersonnelComposition
                personnelNum={personnelNum}
                placeInfo={placeInfo}
              />
              <TravelRule
                data={countPersonFrequencyData}
                type={countPersonFrequencyType}
                countPersonFrequencyTypeChange={
                  this.countPersonFrequencyTypeChange
                }
              />
              <VisitorsFlowrate
                data={countPersonNumData}
                type={countPersonNumType}
                countPersonNumTypeChange={this.countPersonNumTypeChange}
              />
            </div>
          </Spin>
        </div>
      </div>
    );
  }
}

export default placeFile;
