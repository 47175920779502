import React from "react";
import { withRouter } from "react-router-dom";
import { message } from "antd";
const FrameCard = Loader.loadBusinessComponent("FrameCard");
const SimpleMap = Loader.loadBusinessComponent("MapComponent", "SimpleMap");

@withRouter
@Decorator.businessProvider("tab")
class PlaceDevice extends React.Component {
  goPage = (moduleName, data) => {
    this.props.tab.goPage({
      moduleName,
      location: this.props.location,
      data,
      isUpdate: false
    });
  };
  markerClick = id => {
    let {deviceList}= this.props
    // const logInfo = Service.url.request.enterDeviceFileModule;
    // Service.logger.save({
    //   description: `查看场所【${placeInfo.areaName}】下设备【${deviceList.find(v => v.cid === id).deviceName}】档案`,
    //   ...logInfo
    // });
    let deviceInfo = deviceList.find(v => { return v.cid === id })
    let igArr = ["103501", "103502", "118901", "100605"];
    if (igArr.indexOf(deviceInfo.deviceType) > -1) {
      let item = Dict.map.deviceType.find(v =>
        v.value.split(",").includes(deviceInfo.deviceType)
      );
      message.warn(`${item.label}档案功能正在开发中，敬请期待`);
      return;
    }
    this.goPage("deviceFile", { id });
  };

  render() {
    let {placeTypeNum = {}, deviceList,placeInfo,tagsPlaces,} = this.props;
    return (
      <FrameCard title="资源分布：">
        <div className="place-device-view">
          <SimpleMap
            points={deviceList}
            zoom={placeInfo.id =='1200000000000000000'?4 :placeInfo.level === 0 ? 7 : 13}
            polyline={placeInfo.polyline}
            id={placeInfo.id}
            placeTypeNum={placeTypeNum}
            placePoints={tagsPlaces}
            center={placeInfo.center && placeInfo.center.split(",")}
            markerClick={this.markerClick}
          />
        </div>
      </FrameCard>
    );
  }
}

export default PlaceDevice;
