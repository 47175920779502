import React from 'react';
import moment from "moment";
import { Radio } from "antd";
const RadioGroup = Radio.Group;
const { selectTime } = Dict.map;
const FrameCard = Loader.loadBusinessComponent("FrameCard");
const EchartsReact = Loader.loadBaseComponent('EchartsReact')
class TravelRule extends React.Component {
  change = (e ) => {
    this.props.countPersonFrequencyTypeChange && this.props.countPersonFrequencyTypeChange(e.target.value);
  }
  //前位补0 并余24 
  add0 = num => {
    num = num%24
    if(num<10){
      num = '0'+num
    }
    return num;
  }
  getOtionTem() {
    let { type, data={}} = this.props
    let seriesName=['当天','前一天','平均（往前3月内平均统计值）']
    let xAxisData = [];
    let color = ['#FFAA00', '#25DC9B', '#B5BBC7']
    let areaColor = [['rgba(255,170,0,0.2)', 'rgba(255,170,0,0)'], ['rgba(37,220,155,0.2)', 'rgba(37,220,155,0)'], ['rgba(181,187,199,0.2)', 'rgba(181,187,199,0)']]
    let dataList = [[], [], []]
    if( type === 1){
      let timeScale=[
        { time: [3,4], value: '凌晨' },
        { time: [5,6,7], value: '早晨' },
        { time: [8,9,10,11], value: '上午' },
        { time: [12,13], value: '中午' },
        { time: [14,15,16,17], value: '下午' },
        { time: [18], value: '傍晚' },
        { time: [19,20,21,22], value: '晚上' },
        { time: [23,24,25,26], value: '深夜' },
      ]
      for (let i = 0; i < 8; i++) {
        dataList[0][i] = 0
        dataList[1][i] = 0
        dataList[2][i] = 0
      }
      let toDayStart = moment(moment().format("YYYY-MM-DD")) * 1;
      let yesterDayStart = moment(moment().subtract(1, 'days').format('YYYY-MM-DD'))*1
      timeScale.map((v,k) => {
        xAxisData.push(`${v.value}\n${this.add0(v.time[0])}:00~${this.add0(v.time[v.time.length-1]+1)}:00`);
        v.time.map(x => {
          if (data.countAvgPerson && data.countAvgPerson[0]){
            dataList[2][k] += data.countAvgPerson[0][`h${this.add0(x)}`];
          }
          if(data.countPerson&&data.countPerson.find(v => { return v.date === moment(toDayStart+x*60*60*1000).format('YYYY-MM-DD') })){
            dataList[0][k] += data.countPerson.find(v => { return v.date === moment(toDayStart+x*60*60*1000).format('YYYY-MM-DD') })[`h${this.add0(x)}`];
          }
          if(data.countPerson&&data.countPerson.find(v => { return v.date === moment(yesterDayStart+x*60*60*1000).format('YYYY-MM-DD') })){
            dataList[1][k] += data.countPerson.find(v => { return v.date === moment(yesterDayStart+x*60*60*1000).format('YYYY-MM-DD') })[`h${this.add0(x)}`];
          }
        })
        return dataList;
      })
    }
    if (type === 2) {
      for (let i = 0; i < 7; i++) {
        dataList[0][i] = 0
        dataList[1][i] = 0
        dataList[2][i] = 0
      }
      let thisWeekFirstDay = moment().week(moment().week()).startOf('week') * 1
      data.countAvgPerson && data.countAvgPerson.map(v => {
        dataList[2][(v.week + 5)%7] = v.total;
        return dataList
      });
      data.countPerson && data.countPerson.map(v => {
        if (moment(v.date) >= thisWeekFirstDay) {
          dataList[0][(v.week+5)%7] = v.total;
        } else {
          dataList[1][(v.week + 5) % 7] = v.total;
        }
        return dataList;
      })
      seriesName = ['当前周', '上一周', '平均（往前3月内平均统计值）']
      xAxisData = ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期日',]
    }
    if (type === 3) {
      let arr = [];
      for (let i = 0; i < 31; i++) {
        arr[i] = i + 1;
        dataList[0][i] = 0
        dataList[1][i] = 0
        dataList[2][i] = 0
      }
      let thisMonthFirstDay = moment().month(moment().month()).startOf('month') * 1
      data.countAvgPerson && data.countAvgPerson.map(v => {
        dataList[2][v.monthday - 1] = v.total;
      });
      data.countPerson && data.countPerson.map(v => {
        if (moment(v.date) >= thisMonthFirstDay) {
          dataList[0][v.monthday - 1] = v.total;
        } else {
          dataList[1][v.monthday - 1] = v.total;
        }
      });
      seriesName = ['当前月', '上一月', '平均']
      xAxisData = arr
    }

    let series=[]
    seriesName.map((v, k) => {
      let seriesItem = {};
      if (k === 2) {
        seriesItem = {
          name: v,
          type: "line",
          step: "middle",
          // smooth: true,
          symbol: "circle",
          symbolSize: 5,
          showSymbol: false,
          lineStyle: {
            color: color[k],
            normal: {
              width: 1
            }
          },
          itemStyle: {
            normal: {
              color: color[k],
              borderWidth: 1,
              borderColor: "#fff"
            }
          },
          areaStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1,
                [
                  {
                    offset: 0,
                    color: areaColor[k][0]
                  },
                  {
                    offset: 1,
                    color: areaColor[k][1]
                  }
                ],
                false
              ),
              shadowColor: "rgba(0, 0, 0, 0.1)",
              shadowBlur: 10
            }
          },
          data: dataList[k]
        };
      } else {
        seriesItem = {
          name: v,
          type: "bar",
          smooth: true,
          lineStyle: {
            color: color[k]
          },
          barWidth: type === 2 ? 16 : 8,
          barGap: 0.5,
          itemStyle: {
            normal: {
              color: color[k]
            }
          },
          data: dataList[k]
        };
      }
      series.push(seriesItem);
      return series;
    });
    const option = {
      color: color,
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
          shadowStyle: {
            color: 'rgba(255,136,0,0.20)'
          }
        },
        confine: true
      },
      legend: {
        icon: 'roundRect',
        itemGap: 48,
        itemWidth: 18,
        itemHeight: 3,
        bottom: 15,
        data: seriesName,
      },
      grid: {
        top: 50,
        left: 60,
        right: 60,
        bottom: 70,
      },
      xAxis: {
        type: 'category',
        splitNumber: 24,
        boundaryGap: ['20%', '20%'],
        axisTick: true,
        splitLine: {
          show: true,
          lineStyle: {
            color: '#fff',
            width:2,
          }
        },
        axisLabel: {
          color: '#666',
          fontSize: 12,
          fontFamily: 'Microsoft YaHei',
        },
        axisLine: {
          show: false,
        },
        data: xAxisData
      },
      yAxis: {
        type: 'value',
        axisTick: false,
        name:'人次',
        axisLine: {
          show: false,
        },
        axisLabel: {
          color: '#666',
          fontSize: 12,
          fontFamily: 'Microsoft YaHei',
        },
        splitLine: {
          show: false
        },
        splitArea: {
          show: true,
          areaStyle: {
            color: ['#F3F6F9','#fff']
          }
        },
      },
      series: series
    };

    return option;
  }
  render() {
    let {type} = this.props
    return(
      <FrameCard
        title="人员出入规律："
        headerOperator={
          <RadioGroup options={selectTime} onChange={this.change} value={type} />
        }
      >
        <div className="visitors-flowrate-info">
          <EchartsReact option={this.getOtionTem()} style={{ width: '100%', height: '100%' }} />
        </div>
      </FrameCard>
    ) 
  }
}

export default TravelRule