import React from 'react';
const { placeTypeWithIcon, placeFeature} = Dict.map;
const IconFont = Loader.loadBaseComponent("IconFont");
const FrameCard = Loader.loadBusinessComponent("FrameCard");
const PlaceLabelModal = Loader.loadBusinessComponent("PlaceLabelModal");

class PlaceLabel extends React.Component {
  constructor(props) {
    super(props);

  }

  changeLabelModal = () => {
    this.props.changeLabelModal()
  };
  handleLabelCancel = () => {
    this.props.handleLabelCancel()
  };
  handleLabelOK = () => {
    this.props.handleLabelOK()
  };

  render() {
    let { itemClick, tags, labelVisible} = this.props;
    let placeType = placeTypeWithIcon.concat(placeFeature);
    let activeTags = tags.placeTags.concat(tags.featureTags)
    return (
      <FrameCard
        title="场所标签："
        headerOperator={
          <div className="place-label-edit" onClick={this.changeLabelModal}>
            <IconFont type={"icon-S_Edit_Edit"} theme="outlined" /> 编辑
          </div>
        }
      >
        <div className="place-label-view">
          {
            activeTags.length?
            activeTags.map(v => {
              let item = placeType.find(i => {
                return i.value === v;
              })
              return (
                item && item.label &&
                <div className="place-label-item">
                  {
                    item.label
                  }
                </div>
              );
            })
            :
              <div className='no-label'>暂无标签</div>
          }
        </div>

        <PlaceLabelModal
          visible={labelVisible}
          onOk={this.handleLabelOK}
          onCancel={this.handleLabelCancel}
          itemClick={itemClick}
          tags={tags}
        />
      </FrameCard>
    );
  }
}

export default PlaceLabel