import React from 'react';
import { withRouter } from "react-router-dom";
import { Button, Progress } from 'antd'
const IconFont = Loader.loadBaseComponent("IconFont");
const InfoIconProgessView = Loader.loadBusinessComponent("InfoIconProgessView");
const FrameCard = Loader.loadBusinessComponent("FrameCard");
@withRouter
@Decorator.businessProvider('tab')
class PersonnelComposition extends React.Component {
  goPage = (moduleName, data) => {
    
    this.props.tab.goPage({
      moduleName,
      location: this.props.location,
      data,
      isUpdate: false
    });
  };
  goPersonnelCompositionView = (type) => {
    let { placeInfo } = this.props
    window.LM_DB.add('parameter', {
      id: placeInfo.id ,
      placeInfo: placeInfo,
      type,
    }).then(() => {
      this.goPage("personnelCompositionAnalysis", { id: placeInfo.id });
    })
  }
  render() {
    let { personnelNum, placeInfo} = this.props
    let { totalPersonnelNum, frequentPersonnelNum, temporaryPersonnelNum, unFrequentPersonnelNum } = personnelNum
    return <FrameCard 
        title="人员组成分析："
      headerOperator={placeInfo.id&&<Button className='view-detail-btn' onClick={this.goPersonnelCompositionView.bind(this,'1')}><IconFont type="icon-L_Place_Place" />查看详情</Button>}
      >
        <div className="personnel-composition-total-view" >
        <span onClick={this.goPersonnelCompositionView.bind(this, '4')} className='total-title'>全部出现人员总数：</span><span onClick={this.goPersonnelCompositionView.bind(this, '4')} className='total-num'>{totalPersonnelNum}</span>
        </div>
        <div className="personnel-composition-view">
          <InfoIconProgessView title={"最常出现人员"} click={this.goPersonnelCompositionView.bind(this,'1')} icon={"icon-L_AID_People"} num={frequentPersonnelNum} percent={frequentPersonnelNum/totalPersonnelNum} />
          <InfoIconProgessView title={"临时出现人员"} click={this.goPersonnelCompositionView.bind(this,'2')} icon={"icon-L_AID_Temporary"} num={temporaryPersonnelNum} percent={temporaryPersonnelNum / totalPersonnelNum} />
          <InfoIconProgessView title={"长期未出现人员"} click={this.goPersonnelCompositionView.bind(this,'3')} icon={"icon-L_AID_NotAppearing"} num={unFrequentPersonnelNum} percent={unFrequentPersonnelNum / totalPersonnelNum}/>
        </div>
      </FrameCard>; 
  }
}

export default PersonnelComposition